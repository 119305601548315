<template>
<div></div>
</template>

<script>
export default {}
</script>

<style>
* {
  font-family: 'IRANSans';
}
</style>